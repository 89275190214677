import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VLayout,{attrs:{"row":"","align-content-space-between":""}},[_c(VFlex,{attrs:{"xs3":""}},[_c(VAutocomplete,{attrs:{"label":"Kjøpesenter","items":_vm.centerFilterItems,"item-value":"id","item-text":"name","prepend-icon":"mdi-database-search","clearable":""},model:{value:(_vm.centerId),callback:function ($$v) {_vm.centerId=$$v},expression:"centerId"}})],1),_c(VFlex,{attrs:{"xs3":""}},[_c(VSelect,{attrs:{"label":"Kategori","items":_vm.categoryFilterItems,"item-value":"id","item-text":"name"},model:{value:(_vm.categoryId),callback:function ($$v) {_vm.categoryId=$$v},expression:"categoryId"}})],1),_c(VFlex,{attrs:{"xs3":""}},[_c(VSelect,{attrs:{"label":"Underkategori","items":_vm.subcategoryFilterItems,"item-value":"subcategoryId"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.subcategoryId),callback:function ($$v) {_vm.subcategoryId=$$v},expression:"subcategoryId"}})],1),_c(VFlex,{attrs:{"xs3":""}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Søk","placeholder":"Søk etter navn eller ID...","single-line":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.filteredMasterStores,"item-key":"masterStoreId","sort-by":"lgVersions[0].name","loading":_vm.loading,"search":_vm.search,"footer-props":{ itemsPerPageOptions: [5, 10, 20, 50] },"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){$event.stopPropagation();return _vm.showItemDialog(item)}}},[_c('td',[_vm._v(_vm._s(item.masterStoreId))]),_c('td',[_vm._v(_vm._s(_vm.getUiVersion(item).name))]),_c('td',[_vm._v(_vm._s(_vm.formatCategories(item.categories)))]),_c('td',[_vm._v(_vm._s(_vm.formatSubcategories(item.subcategories)))]),_c('td',_vm._l((item.lgVersions),function(lgVersion){return _c('country-flag',{key:lgVersion.lgCode,attrs:{"country":_vm.countryCodeForLgCode(lgVersion.lgCode),"size":"small"}})}),1),_c('td',[_vm._v(_vm._s(_vm.formatDateTime(item.lastUpdated)))]),_c('td',[_c(VIcon,{attrs:{"color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_vm._v(" mdi-delete-forever ")])],1)])]}}])},[_c(VProgressLinear,{attrs:{"color":"accent","indeterminate":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}])})],1)],1),_c(VCardActions,[_c(VSpacer),_c('DialogEdit',{attrs:{"title":"Opprett masterbutikk","create-model":_vm.createNewModel,"save":_vm.save,"support-languages":true,"width":960},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v(" mdi-playlist-plus ")])]}},{key:"edit",fn:function(ref){
var model = ref.model;
var language = ref.language;
return [_c('BasedataMasterStoreEdit',{attrs:{"model":model,"language":language,"create-lg-version":_vm.createLgVersion}})]}}])})],1),_c('DialogEdit',{ref:"itemDialog",attrs:{"title":"Masterbutikk","edit-title":"Rediger masterbutikk","save":_vm.save,"support-languages":true,"on-delete-language-version":_vm.onDeleteLanguageVersion,"close":_vm.closeEditDialog,"width":960},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var model = ref.model;
var lgModel = ref.lgModel;
var language = ref.language;
return [_c('BasedataMasterStoreView',{attrs:{"model":model,"language":language},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})]}},{key:"edit",fn:function(ref){
var model = ref.model;
var lgModel = ref.lgModel;
var language = ref.language;
return [_c('BasedataMasterStoreEdit',{attrs:{"model":model,"language":language,"create-lg-version":_vm.createLgVersion},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }