import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"mandatory":""},model:{value:(_vm.selectedIndex),callback:function ($$v) {_vm.selectedIndex=$$v},expression:"selectedIndex"}},_vm._l((_vm.listItems),function(item,index){return _c(VListItem,{key:index},[_c(VCheckbox,{attrs:{"disabled":_vm.disabled},on:{"change":function($event){return _vm.includedChanged(item)}},model:{value:(item.included),callback:function ($$v) {_vm.$set(item, "included", $$v)},expression:"item.included"}}),_c(VListItemContent,[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1),_c(VCol,{attrs:{"cols":"8"}},[(_vm.editItem)?_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.editItemName))]),_c(VCardText,[_c('HtmlEditor',{attrs:{"label":"Beskrivelse","placeholder":"Kan overstige 300 tegn","toolbar":_vm.editorToolbar,"max-length":300,"suggested-max-only":true,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('country-flag',{attrs:{"country":_vm.language.flagCountryCode,"size":"small"}})]},proxy:true}],null,false,2910774066),model:{value:(_vm.editItemLgVersion.description),callback:function ($$v) {_vm.$set(_vm.editItemLgVersion, "description", $$v)},expression:"editItemLgVersion.description"}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }